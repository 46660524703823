import React from "react"
import Layout from "../components/layout"
import TopHead from "../components/top-head"
import { graphql } from "gatsby"
import arrowSVG from "../../static/arrow.svg"
import { Helmet } from 'react-helmet'

class FAQBlock extends React.Component {

  render() {
    const { question, answer, toggleDisplay, indState } = this.props
    const block = indState ? "max-h-500" : "max-h-0"
    const anim = indState
      ? "max-height 0.7s ease-in-out"
      : "max-height 0.5s cubic-bezier(0, 1, 0, 1)"
    const arrow = indState ? "rotate(270deg)" : "rotate(90deg)"
    return (
      <>
        <button
          onClick={toggleDisplay}
          className={
            "focus:outline-none m-auto w-11/12 pt-5 pb-5 md:pt-4 md:pb-4 flex items-center justify-between"
          }
        >
          <h2 className="text-left text-lg opacity-75 hover:opacity-100 mr-5">{question}</h2>
          <img
            src={arrowSVG}
            alt=""
            width="18px"
            style={{ transform: arrow, userDrag: "none", userSelect: "none" }}
            className=""
          ></img>
        </button>
        <div
          className={"w-11/12 m-auto overflow-hidden " + block}
          style={{ transition: anim }}
        >
          <p className="text-md text-left mt-3 mb-3 md:mr-20 sm:mr-5 mr-2">
            {answer}
          </p>
        </div>
        <div className="h-px w-11/12 m-auto bg-gray-200"></div>
      </>
    )
  }
}

class FAQSec extends React.Component {
  state = {
    blockOpen: [false, false, false],

  };

  toggleDisplay = (i) => {
    let arr = this.state.blockOpen;
    if(arr[i]) {
      arr.fill(false);
    } else {
      arr.fill(false);
      arr[i] = true;
    }
    this.setState((prevState) => {
      return {blockOpen: arr};
    });
  }

  render() {
    return (
      <section className="md:max-w-6xl w-11/12 mb-10 shadow-xl pt-3 pb-6 bg-white rounded">
        <FAQBlock
          toggleDisplay={() => this.toggleDisplay(0)}
          indState={this.state.blockOpen[0]}
          question="Wie lange dauert ein Training?"
          answer="Die Erstaufnahme dauert durchschnittlich eine Stunde. Die Trainingseinheiten und Krafttests dauern jeweils 30 Minuten."
        />
        <FAQBlock
          toggleDisplay={() => this.toggleDisplay(1)}
          indState= {this.state.blockOpen[1]}
          question="Was muss ich mitbringen und welche Kleidung brauche ich?"
          answer="Das Training kann grundsätzlich in jeder bequemen Kleidung
          absolviert werden. Saubere Turnschuhe, ein Handtuch und Ihre
          ärztliche Verordnung mit der Diagnose sind das Einzige, was Sie
          mitbringen müssen."
        />
        <FAQBlock
          toggleDisplay={() => this.toggleDisplay(2)}
          indState={this.state.blockOpen[2]}
          question="Gibt es Parkplätze?"
          answer="Ja! Es gibt ein Parkhaus und Parkplätze direkt auf dem Grundstück der Hochtaunuskliniken neben dem Gesundheitscampus."
        />
        <FAQBlock
          toggleDisplay={() => this.toggleDisplay(3)}
          indState={this.state.blockOpen[3]}
          question="Wer übernimmt die Kosten?"
          answer="Die meisten privaten Krankenversicherungen übernehmen
          die Kosten der Therapie in vollem Umfang oder zu einem
          bestimmten Teil (abhängig von den
          Versicherungsvereinbarungen). Bei gesetzlich versicherten
          Patienten ist die Therapie in der Regel eine
          Selbstzahlerleistung."
        />
        {/* <FAQBlock
          toggleDisplay={() => this.toggleDisplay(4)}
          indState={this.state.blockOpen[4]}
          question="Kann ich nicht einfach Physiotherapie/Krankengymnastik machen?"
          answer="Nein. Die medizinische Trainingstherapie (MTT) adressiert die tiefe Rückenmuskulatur, während die Physiotherapie die Oberflächenmuskulatur beübt."
        /> */}
      </section>
    )
  }
}

const FAQ = props => (
  <>
  <Helmet title="Häufige Fragen | IRT" defer={false} />
  <Layout>
    <TopHead
      photo={props.data.imageOne.childImageSharp.fluid}
      title="Unser FAQ"
      para="Fragen, die viele Kunden gestellt haben, direkt beantwortet."
    />
    <FAQSec />
  </Layout>
  </>
)

export default FAQ

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "photo1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
